export const suppliers = [
  "Circuitos Europeus com Special Tours",
  "Caribe com Grupo Posadas",
  "Estados Unidos - Washington D.C",
  "Caribe com Hilton All Inclusive",
  "Caribe com Iberostar",
  "Caribe com Playa Resorts",
  "Estados Unidos com South Beach Group Miami",
  "Caribe com Palace Resorts",
  "Maldivas com Heritance e Adaaran",
  "Egito com Exotic Travel",
  "Grécia com Renaissance Tours & Travel",
  "Itália com Carrani",
  "Circuitos Europeus com Surland",
  "Voe ITA Airways",
  "Nepal e Butão com The Himalaya Trekking Company",
  "Turismo da Jamaica",
  "Turismo de Aruba com  Vitrine Global",
  "Estados Unidos - Visit Orlando",
  "Reino Unido com ATS Group",
  "Argentina com Quality Travel",
  "Chile com CTS",
  "Voe SKY Airlines",
  "Colômbia com All Reps",
  "Peru com VIPAC",
  "Voe TAP Airlines",
  "Espanha – Turespaña",
  "Estados Unidos - Santa Monica Travel",
  "Turquia com Crystal Concepts",
  "Motorhomes nos Estados Unidos & Canadá com Cruise America",
  "Caribe com Meliá Hotels",
  "Estados Unidos - San Francisco",
  "Marrocos com Visit Marroco & KTI",
  "Caribe com Sandals Resorts",
  "Panamá & Copa Airlines",
  "Reino Unido com a GJ Travel",
  "Estados Unidos - Greater Miami",
  "Caribe com The Five Hotels",
  "Estados Unidos - Sul dos Estados Unidos",
  "Estados Unidos - San Diego",
  "Hero Seguros",
  "Estados Unidos - St Pete & Clearwater",
  "Caribe com Palladium Resorts",
  "Cruzeiros com NCL Norwegian Cruise Line",
  "Coris Seguros",
  "Estados Unidos - Visit Florida",
  "Locação de carros com Alamo Rental Cars",
  "Estados Unidos - Nova York Tourism and Convention",
  "Turismo do Egito",
  "Caribe Mexicano com a AT Travel",
  "Caribe com RCD Hotels",
  "Voe American Airlines",
  "Israel com Conexion Travel Sar-El",
  "Chile com Sernatur",
  "Estados Unidos com Brand USA",
  "Cuba com Enjoy Travel Group",
  "Caribe com Decameron Resorts",
  "China com Enjoy China Tour",
  "Estados Unidos - Hudson Yards & The Edge NYC",
  "Uruguai com Enjoy Punta del Este",
  "Voe com Air Europe",
];
