import React from "react";
import ItemRoomOption from "./itemRoom";
import "../styled.css";

export default function ListAllRooms({
  setStep,
  setRoomSelected,
  setIsSupplier,
  user,
  allRoomsSaved,
}) {
  const rooms = [
    "Aula USG Beira Leito",
    "Aula Eslatografia",
    "Aula Dispositivos Inalatórios",
  ];

  return (
    <div className="div-items-list">
      {rooms.map((room, index) => (
        <div key={index} style={{ width: "100%" }}>
          <ItemRoomOption
            title={room}
            setStep={setStep}
            setRoomSelected={setRoomSelected}
            setIsSupplier={setIsSupplier}
            user={user}
            allRoomsSaved={allRoomsSaved}
          />
        </div>
      ))}
    </div>
  );
}
