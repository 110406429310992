import React, { useState } from "react";
import "../styled.css";
// moment
import moment from "moment";
// redux
import { useSelector } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { deleteSchedule } from "../../../services/scheduling";

export default function ItemSaved(props) {
  const { data, getListSaved } = props;
  // redux
  const { user, config } = useSelector((state) => state.scheduling);
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);

  function deleteScheduleFunc() {
    deleteSchedule(data.id)
      .then((res) => {
        if (res.message === "DELETED") {
          setSuccess(true);
          setConfirm(false);
        }
      })
      .catch((error) => console.log("erro", error));

    return;
  }

  return (
    <>
      {confirm && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title="Deseja excluir essa atividade da sua agenda?"
          onCancel={() => setConfirm(false)}
          onConfirm={() => deleteScheduleFunc()}
        />
      )}

      {success && (
        <SweetAlert
          success
          title="Atividade removida com sucesso!"
          onConfirm={() => {
            setSuccess(false);
            getListSaved(user.idEvent, user.idPart);
          }}
        />
      )}

      <div className="div-card-item" style={{ marginTop: 20 }}>
        <i className="bx bx-check-circle icon-save-list" />
        <div className="div-data-list">
          <a style={{ fontWeight: "bold" }}>{`${data.sala} -   ${moment(
            data.dataHoraInicio
          ).format("H:mm")}`}</a>
          <a>{data.atividade}</a>
        </div>
        {config.allowDeleteAppointment && (
          <i
            className="bx bx-trash icon-delete-list"
            onClick={() => setConfirm(true)}
          />
        )}
      </div>
    </>
  );
}
