import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { createSchedule } from "../../../services/diversaTurismo";
import "../styled.css";

export default function ItemTimeOption({ data, user, setStep }) {
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const saveSchedule = async () => {
    const dataSchedule = {
      eventId: user.idEvent,
      participantId: user.idPart,
      atividade: data.atividade,
      sala: data.sala,
      dataHoraInicio: data.dataHoraInicio,
      dataHoraFim: data.dataHoraFim,
    };

    try {
      const res = await createSchedule(dataSchedule);
      handleResponse(res);
    } catch {
      setError("Erro ao se cadastrar na atividade, tente novamente.");
    }
  };

  const handleResponse = (res) => {
    switch (res.message) {
      case "CREATED":
        setSuccess(true);
        break;
      case "APPOINTMENT_MADE":
        setError("Você já se inscreveu nessa atividade");
        break;
      case "APPOINTMENT_MADE_IN_OTHER_TIME":
        setError("Você já está inscrito em uma atividade nesse horário");
        break;
      default:
        setError("Erro ao se cadastrar na atividade, tente novamente.");
    }
    setConfirm(false);
  };

  if (data.quantidadeUtilizada >= data.quantidadeMax) return null;

  return (
    <>
      {confirm && (
        <SweetAlert
          warning
          title="Deseja se inscrever nessa sala e horário?"
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={saveSchedule}
          onCancel={() => setConfirm(false)}
        />
      )}
      {success && (
        <SweetAlert
          success
          title="Cadastro realizado com sucesso!"
          onConfirm={() => {
            setStep(1);
            setSuccess(false);
          }}
        />
      )}
      {error && (
        <SweetAlert danger title={error} onConfirm={() => setError("")} />
      )}
      <div
        className="div-card-item"
        onClick={() => {
          setConfirm(true);
        }}
        // style={{ backgroundColor: daySelected ? "#cecece" : "white" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "4px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a style={{ fontWeight: "bold" }}>{`${moment(data.dataHoraInicio)
              .utc()
              .format("HH:mm")} - ${moment(data.dataHoraFim)
              .utc()
              .format("HH:mm")}`}</a>
            <a>{data.atividade}</a>
          </div>
          <i
            className="bx bx-add-to-queue icon-save-list"
            style={{
              backgroundColor: "white",
              color: "green",
            }}
          />
        </div>
      </div>
    </>
  );
}
