import React, { useState, useEffect } from "react";
import "../styled.css";
// componentes
import ItemSelect from "./itemSelect";
// service
import {
  getScheduleSaved,
  deleteSchedule,
} from "../../../services/diversaTurismo";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function ListSavedRooms(props) {
  const { user } = props;
  const [list, setList] = useState([]);
  // carregamento
  const [loading, setLoading] = useState(false);
  // alerta
  const [deleted, setDeleted] = useState(false);

  function getList() {
    setList([]);
    setLoading(true);
    getScheduleSaved(user.idEvent, user.idPart).then((res) => {
      if (res.data) {
        setList(res.data);
      }

      setLoading(false);
    });
    // .catch((error) => console.log("erro", error));
  }

  function deleteScheduleFunc(schedule) {
    deleteSchedule(schedule.id).then((res) => {
      if (res.message === "DELETED") {
        getList();
        setDeleted(true);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (user) getList();
  }, [user]);

  return (
    <>
      {deleted && (
        <SweetAlert
          success
          title="Deletado!"
          onConfirm={() => setDeleted(false)}
        >
          Atividade deletada com sucesso
        </SweetAlert>
      )}

      <div className="div-items-list">
        {loading ? (
          <a className="a-loading" />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "100%",
              }}
            >
              <p style={{ fontWeight: "bold" }}>Informações importantes:</p>
              <a>
                • Solicitamos que se atentem para agendar apenas 01 atividade
                por horário, caso contrário a atividade será selecionada
                aleatoriamente.
              </a>
              <a>• Pode haver remanejamento na programação sem aviso prévio.</a>
              <a>• Em caso de atraso, seu agendamento poderá ser cancelado.</a>
              <a>• Necessário mínimo de 10 agendamentos para efetivar a participação.</a>
            </div>
            {list.length > 0 ? (
              list
                .sort(function (itemA, itemB) {
                  if (itemA.dataHoraInicio < itemB.dataHoraInicio) return -1;
                  if (itemA.dataHoraInicio > itemB.dataHoraInicio) return 1;
                })
                .map((e, i) => (
                  <div key={i} style={{ width: "100%" }}>
                    <ItemSelect
                      data={e}
                      deleteScheduleFunc={deleteScheduleFunc}
                    />
                  </div>
                ))
            ) : (
              <a style={{ color: "gray", marginTop: 20 }}>
                Você não possui agendas salvas
              </a>
            )}
          </>
        )}
      </div>
    </>
  );
}
