import React, { useState, useEffect } from "react";
import "../styled.css";
// moment
import moment from "moment";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// opções de parceiros
import { suppliers } from "../listSupplier/listOptions";

export default function ItemSelect(props) {
  const { data, deleteScheduleFunc } = props;
  const [confirm, setConfirm] = useState(false);
  // exibir duração
  const [showDuration, setShowDuration] = useState(false);

  function getNameRoom(room) {
    var search = suppliers.find((e) => e === room);

    if (search) {
      return "Circuito de Reuniões - One to one";
    } else return room;
  }

  useEffect(() => {
    if (
      data.atividade === "Espaço Diversa WOW Dubai" ||
      data.atividade === "Ativação: What do you know about ..."
    ) {
      setShowDuration(true);
    }
  }, []);

  return (
    <>
      {confirm && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title="Deseja excluir essa atividade da sua agenda?"
          onCancel={() => setConfirm(false)}
          onConfirm={() => deleteScheduleFunc(data) > setConfirm(false)}
        />
      )}

      <div className="div-card-item">
        <i className="bx bx-check-circle icon-save-list" />
        <div className="div-data-list">
          <a>{getNameRoom(data.sala)}</a>
          <a>
            {!showDuration && data.atividade + " - "}
            {moment(data.dataHoraInicio).utc().format("LT")}
            {showDuration && " - Duração de 20 min"}
          </a>
        </div>
        {/* <i
          className="bx bx-trash icon-delete-list"
          // onClick={() => deleteScheduleFunc(data)}
          onClick={() => setConfirm(true)}
        /> */}
      </div>
    </>
  );
}
