import React, { useState, useEffect } from "react";
import "../styled.css";
// moment
import moment from "moment";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { createSchedule } from "../../../services/scheduling";

export default function ItemRoomTimes(props) {
  const { data, user, setStep, config, saved } = props;
  // alerta
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  // esgotada
  const [noStock, setNoStock] = useState(false);
  // regra 1 por sala
  const [blockRoom, setBlockRoom] = useState(false);

  function createScheduleFunc() {
    createSchedule({
      eventId: user.idEvent,
      sala: data.sala,
      atividade: data.atividade,
      dataHoraInicio: data.dataHoraInicio,
      dataHoraFim: data.dataHoraFim,
      participantId: user.idPart,
    })
      .then((res) => {
        if (res.message === "CREATED") {
          setSuccess(true);
        } else if (res.message === "APPOINTMENT_MADE") {
          setError("Você já se inscreveu nessa atividade");
        } else if (res.message === "APPOINTMENT_MADE_IN_OTHER_TIME") {
          setError("Você já está inscrito em uma atividade nesse horário");
        } else if (res.message === "ALREADY_REGISTERED") {
          setError("Você já se cadastrou nessa atividade");
        } else {
          setError(
            "Erro ao se cadastrar na atividade, tente novamente em breve."
          );
        }

        setConfirm(false);
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (data.quantidadeMax <= data.quantidadeUtilizada) setNoStock(true);

    // regra de 1 por sala
    if (config.regra === "onePerDate") {
      var search = saved.find(
        (e) =>
          moment(e.dataHoraInicio).format("DD/MM/YYY") ===
          moment(data.dataHoraInicio).format("DD/MM/YYY")
      );

      if (search) setBlockRoom(true);
    }

    if (new Date(data.dataHoraInicio) < new Date()) {
      setBlockRoom(true);
    }
  }, []);

  return (
    <>
      {confirm && (
        <SweetAlert
          warning
          title={`Deseja se inscrever em ${data.sala} - ${data.atividade}?`}
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => createScheduleFunc()}
          onCancel={() => setConfirm(false)}
        />
      )}
      {success && (
        <SweetAlert
          success
          title="Cadastro realizado com sucesso!"
          onConfirm={() => {
            setStep(1);
            setSuccess(false);
          }}
        />
      )}

      {error && (
        <SweetAlert error title="Ooops..." onConfirm={() => setError("")}>
          {error}
        </SweetAlert>
      )}

      <div
        className="div-card-item"
        style={{ backgroundColor: noStock || blockRoom ? "#c8c8c8" : "white" }}
        onClick={() => {
          if (!noStock && !blockRoom) setConfirm(true);
        }}
      >
        <div className="div-data-list">
          <a style={{ fontWeight: "bold" }}>{`${moment(
            data.dataHoraInicio
          ).format("H:mm")} -  
          
          ${moment(data.dataHoraFim).format("H:mm")}`}</a>
          <a>{data.atividade}</a>
        </div>
        {!noStock && !blockRoom && (
          <i
            className="bx bx-add-to-queue icon-save-list"
            style={{
              backgroundColor: "white",
              color: "green",
            }}
          />
        )}
      </div>
    </>
  );
}
