const INITIAL_STATE = {
  user: {},
  config: {},
  saved: [],
};

export default function scheduling(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_USER":
      return {
        ...state,
        user: action.payload,
      };

    case "ADD_CONFIG":
      return {
        ...state,
        config: action.payload,
      };

    case "ADD_SAVED":
      return {
        ...state,
        saved: action.payload,
      };
    default:
      return state;
  }
}
