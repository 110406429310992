const addUser = (config) => ({
  type: "ADD_USER",
  payload: config,
});

const addConfig = (config) => ({
  type: "ADD_CONFIG",
  payload: config,
});

const addSaved = (saved) => ({
  type: "ADD_SAVED",
  payload: saved,
});

export { addUser, addConfig, addSaved };
