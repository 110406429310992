import React, { useState, useEffect } from "react";
import "../styled.css";

export default function ItemRoomOption(props) {
  const { setStep, name, setRoomSelected, saved, config } = props;
  // regra 1 por sala
  const [blockRoom, setBlockRoom] = useState(false);

  useEffect(() => {
    // regra de 1 por sala
    if (config.regra === "onePerRoom") {
      var search = saved.find((e) => e.sala === name);

      if (search) setBlockRoom(true);
    }
  }, []);

  return (
    <div
      className="div-card-item"
      style={{ backgroundColor: blockRoom ? "#c8c8c8" : "white" }}
      onClick={() => {
        if (!blockRoom) {
          setRoomSelected(name);
          setStep(3);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "4px",
        }}
      >
        <a>{`${name} - Ver horários`}</a>
        {!blockRoom && (
          <i className="bx bxs-right-arrow-circle icon-save-list" />
        )}
      </div>
    </div>
  );
}
