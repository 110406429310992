import React, { useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
// componentes
import ItemSaved from "./itemSaved";
// moment
import moment from "moment";

export default function ListSaved(props) {
  const { getListSaved } = props;
  const { user, saved } = useSelector((state) => state.scheduling);
  // listagem
  const [list, setList] = useState([]);

  useEffect(() => {
    if (saved.length > 0) {
      var savedFormated = [];

      saved.map((e) => {
        const date = moment(new Date(e.dataHoraInicio)).format("DD/MM/YYYY");

        var search = savedFormated.find((save) => save.date === date);

        if (search) {
          savedFormated.find((save) => save.date === date).data.push(e);
        } else {
          savedFormated.push({
            date: date,
            newDate: e.dataHoraInicio,
            data: [e],
          });
        }
      });

      setList(savedFormated);
    } else setList([]);
  }, [saved]);

  return (
    <>
      {list.length > 0 ? (
        list
          .sort(function (dataA, dataB) {
            return (
              new Date(dataA.newDate).getTime() -
              new Date(dataB.newDate).getTime()
            );
          })
          .map((e, i) => (
            <div key={i}>
              <h2 style={{ textAlign: "center", marginTop: 20 }}>{e.date}</h2>
              {e.data.map((data, index) => (
                <div
                  key={`data${index}`}
                  style={{ width: "100%" }}
                >
                  <ItemSaved
                    data={data}
                    user={user}
                    getListSaved={getListSaved}
                  />
                </div>
              ))}
            </div>
          ))
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <a>Você não possui atividades</a>
        </div>
      )}
    </>
  );
}
