import React, { useState, useEffect } from "react";
// componentes
import ItemRoomTimes from "./ItemRoomTimes";
// redux
import { useSelector } from "react-redux";
// service
import { getActivitiesByRoom } from "../../../services/scheduling";
// moment
import moment from "moment";

export default function ListRoomTimes(props) {
  const { roomSelected, setStep } = props;
  // redux
  const { user, config, saved } = useSelector((state) => state.scheduling);
  const [roomTime, setRoomTime] = useState([]);
  // carregando
  const [loading, setLoading] = useState(true);

  function getList() {
    getActivitiesByRoom(user.idEvent, roomSelected)
      .then((res) => {
        if (res.total > 0) {
          var listFormated = [];

          res.data.map((e) => {
            const date = moment(new Date(e.dataHoraInicio)).format(
              "DD/MM/YYYY"
            );

            var search = listFormated.find((item) => item.date === date);

            if (search) {
              listFormated.find((item) => item.date === date).data.push(e);
            } else {
              listFormated.push({
                date: date,
                newDate: e.dataHoraInicio,
                data: [e],
              });
            }
          });

          setRoomTime(listFormated);
        }

        setLoading(false);
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (roomSelected) getList();
  }, [roomSelected]);

  return (
    <>
      <div className="div-items-list">
        {loading ? (
          <a>Carregando ...</a>
        ) : roomTime.length > 0 ? (
          roomTime.map((e, i) => (
            <div key={i} style={{ width: "100%" }}>
              <h2 style={{ textAlign: "center", marginTop: 20 }}>{e.date}</h2>
              {e.data.map((data, index) => (
                <div key={`data${index}`} style={{ width: "100%" }}>
                  <ItemRoomTimes
                    data={data}
                    user={user}
                    setStep={setStep}
                    config={config}
                    saved={saved}
                  />
                </div>
              ))}
            </div>
          ))
        ) : (
          <a style={{ color: "gray" }}>Não há estoque para essa sala</a>
        )}
      </div>
    </>
  );
}
